import React, {useState} from 'react';
import {graphql} from 'gatsby';
import cx from 'classnames';

import {EditorState, convertToRaw} from 'draft-js';
//import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import loadable from '@loadable/component'

import {Layout} from '@app/components';

import {useDispatch, useSelector} from 'react-redux';

import '../styles/pages/login.scss';
import {Timestamp, addDoc, collection, doc, setDoc} from 'firebase/firestore';
import {db} from '../../firebase';
import {createUserWithEmailAndPassword} from 'firebase/auth';
import {logout} from '../redux/auth';
import slugify from 'slugify';

const Editor = loadable(async () => {
  const { Editor } = await import('react-draft-wysiwyg')

  return Editor;
})

/*const {Editor} = Loadable({
  loader: () => import('react-draft-wysiwyg').then(module => module.Editor),
  loading: Loading
});*/

const Admin: React.FC = () => {
  const {user} = useSelector((state) => state.auth);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [language, setLanguage] = useState('');
  const [categories, setCategories] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [article, setArticle] = useState(EditorState.createEmpty());
  const [pdfUrl, setPdfUrl] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const dispatch = useDispatch();

  const redirectToLoginPage = () => {
    if (typeof window !== 'undefined') {
      window.location.href = '/login';
    }
  };

  if (!user) {
    redirectToLoginPage();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmit(true);

    if(!article.getCurrentContent().hasText() || !author || !categories || !imageUrl || !language || !title) {

      return false;
    }

    const ref = collection(db, 'posts');
    const setPost = await addDoc(ref, {
      article: draftToHtml(convertToRaw(article.getCurrentContent())),
      author,
      categories: categories.split(','),
      createAt: Timestamp.now(),
      imageUrl,
      language,
      url: youtubeUrl,
      titleUrl: slugify(title, {
            lower: true,
            strict: true,
          }),
      title,
      pdfUrl,
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });

    setIsSend(true);
    setIsSubmit(false);
    setAuthor('');
    setArticle(EditorState.createEmpty());
    setCategories('');
    setYoutubeUrl('');
    setImageUrl('');
    setLanguage('');
    setPdfUrl('');
  };

  const onEditorStateChange = (editorState) => {
    setArticle(editorState);
  };

  const handleLogoutClick = () => {
    dispatch(logout());
  };

  return (
    <Layout>
      {user && (
        <div className="layout layout--bg" style={{backgroundImage: 'none'}}>
          <div className="px-6 py-4 layout__inner">
            <div className="text-right">
              <span
                className="text-md cursor-pointer"
                onClick={handleLogoutClick}
              >
                Logout ->
              </span>
            </div>
            <form onSubmit={handleSubmit} className=" m-auto text-center mt-5">
              <div className="w-80 m-auto">
                <div className="text-xl font-bold">Add Article</div>
                <input
                  className={cx("login-input block text-lg w-full py-3 px-3 my-3", {'error': isSubmit && !title})}
                  type="text"
                  value={title}
                  placeholder="Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <input
                  className={cx("login-input block text-lg w-full py-3 px-3 my-3", {'error': isSubmit && !author})}
                  type="text"
                  value={author}
                  placeholder="Author"
                  onChange={(e) => setAuthor(e.target.value)}
                />
                <input
                  className={cx("login-input block text-lg w-full py-3 px-3 my-3", {'error': isSubmit && !imageUrl})}
                  type="text"
                  value={imageUrl}
                  placeholder="Image URL"
                  onChange={(e) => setImageUrl(e.target.value)}
                />
                <input
                  className={cx("login-input block text-lg w-full py-3 px-3 my-3")}
                  type="text"
                  value={pdfUrl}
                  placeholder="Pdf URL"
                  onChange={(e) => setPdfUrl(e.target.value)}
                />
                <input
                  className={cx("login-input block text-lg w-full py-3 px-3 my-3")}
                  type="text"
                  value={youtubeUrl}
                  placeholder="Youtube URL"
                  onChange={(e) => setYoutubeUrl(e.target.value)}
                />
                <select
                  className={cx("login-input block text-lg w-full py-3 px-3 my-3", {'error': isSubmit && !language})}
                  value={language}
                  placeholder="Language"
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value={''}>Select Language</option>
                  <option value={'tr'}>tr</option>
                  <option value={'en'}>en</option>
                </select>
                <input
                  className={cx("login-input block text-lg w-full py-3 px-3 my-3", {'error': isSubmit && !categories})}
                  type="text"
                  value={categories}
                  placeholder="Categories"
                  onChange={(e) => setCategories(e.target.value)}
                />
                <span className="text-xs mb-5 block text-red">
                  Please separate the categories with commas. <br />
                  (etc: Live, Editor)
                </span>
              </div>

              <div className="w-2/4 m-auto h-96">
                {Editor &&
                  <Editor
                  editorState={article}
                  style={{width: '640px'}}
                  wrapperClassName="h-60"
                  editorClassName={cx("bg-gray-50 px-5", {'error': isSubmit && !article?.getCurrentContent()?.hasText()})} 
                  onEditorStateChange={onEditorStateChange}
                />
                }
              </div>
              <div className="w-80 m-auto">
                <button type="submit" className="login-button my-5 py-3 px-3">
                  Add
                </button>

                <span className={`${isSend ? "" : "opacity-0"}`}>Başarılı bir şekilde kaydedilmiştir.</span>
              </div>
            </form>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Admin;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
